/*--------------------------------------------------
	Common
--------------------------------------------------*/



function scripts(){

	setMenuColumns()
	runCustomAccordion('.menu__list', '.menu__list__item__button', '.menu__list__item__sub', 400);

	const hero = document.querySelector(".hero__slideshow");
	const heroPause = document.querySelector('.hero__slideshow__controls__pause')
    const heroSwiper = createSwiper(hero, true, {  
		init: true,  
		allowTouchMove: false,
		slidesPerView: 1,
		watchSlidesProgress: true,
		watchSlidesVisibility: true,
		slideVisibleClass: 'swiper-slide-visible',
		speed: 1000,
		loop: true,
		effect: "fade",
		autoplay: blnReducedMotion ? false : {
			delay: 6000,
			disableOnInteraction: false,
		},
		navigation: {
			prevEl: ".hero__slideshow__controls__prev",
			nextEl: ".hero__slideshow__controls__next",
		},		
		extendedInit: function() {
			bindSwiperPause(this, heroPause)
		},
		a11y: {
			enabled: true,
			id: 'heroSlideshow',
			prevSlideMessage: 'Previous Slideshow Item',
			nextSlideMessage: 'Next Slideshow Item',
			firstSlideMessage: 'This is the first slide',
			lastSlideMessage: 'This is the last slide',
			paginationBulletMessage: 'Go to slide {{index}}',
			slideLabelMessage: '{{index}} / {{slidesLength}}',
		},	
	});

	/* Accessibility */
	var ocularContainer = $('.ocular')
    ocularContainer.each(function() {
        $(this).ocular({
            hideClass: 'img',
            invertClass: '.header',
            fonts: [
               ["Georgia", ""],
               ["Tahoma", ""],
               ["Verdana", ""],
               ["Maven Pro", "https://fonts.googleapis.com/css?family=Maven+Pro"]
            ],
        });
    })
	

/*--------------------------------------------------
	Home
--------------------------------------------------*/
	if (blnHome){

		/* NEWS */
		let newsList = document.getElementsByClassName('news__list')[0]
		if (newsList !== null && newsList !== undefined) {
			createSwiper(newsList, false, {
				init: true,
				autoplay: false,
				loop: false,
				centeredSlides: false,
				spaceBetween: 25,
				grabCursor: true,
				watchSlidesProgress: true,
				watchSlidesVisibility: true,
				navigation: {
					nextEl: ".nav__next__news",
					prevEl: ".nav__prev__news",
				},
				pagination: {
					el: ".swiper-pagination-news",
					clickable: true,
				},
				slideVisibleClass: 'swiper-slide-visible',
				breakpoints: {
					600: {
						spaceBetween: 50,
						slidesPerView: 2,
					},
					800: {
						spaceBetween: 50,
						slidesPerView: 2.5,
					},
					1000: {
						spaceBetween: 50,
						slidesPerView: 3,
					},
				},
				a11y: {
					enabled: true,
					id: 'news__list',
					prevSlideMessage: 'Previous slide',
					nextSlideMessage: 'Next slide',
					firstSlideMessage: 'This is the first slide',
					lastSlideMessage: 'This is the last slide',
					paginationBulletMessage: 'Go to slide {{index}}',
					slideLabelMessage: '{{index}} / {{slidesLength}}',
				},
			})
		}

		
		const mixedFilters = document.getElementById('mixed__filters');
		const mixedList = document.getElementById('mixed__list');
		let mixedSwiper;
		if (mixedFilters !== null) {
			buildFilters(mixedFilters, mixedList, function() {
				let socialItems = mixedList.querySelectorAll('.social__item');
				mixedFilters.addEventListener('click', function(event) {
					const filter = event.target.closest('[data-social]');
					if (!filter) return;
					const socialFilter = filter.getAttribute('data-social');
					mixedFilters.querySelector('.active').classList.remove('active')
					filter.classList.add('active')
					socialItems.forEach(social => {
						const socialTag = social.getAttribute('data-type');
						social.style.display = (socialFilter === 'all' || socialTag === socialFilter) ? 'block' : 'none';
					});		
					mixedSwiper.update();
				});
			});
		}
		if (mixedList !== null) {
			mixedSwiper = createSwiper(mixedList, false, {
				init: true,
				autoplay: false,
				loop: true,
				centeredSlides: true,
				spaceBetween: 25,
				grabCursor: true,
				slidesPerView: 1,
				watchSlidesProgress: true,
				watchSlidesVisibility: true,
				navigation: {
					prevEl: ".nav__prev__social",
					nextEl: ".nav__next__social",
				},
				pagination: {
					el: ".swiper-pagination-social",
					clickable: true,
				},
				breakpoints: {
					600: {
						spaceBetween: 50,
						slidesPerView: 2,
					},
					800: {
						spaceBetween: 50,
						slidesPerView: 2.5,
					},
					1000: {
						spaceBetween: 50,
						slidesPerView: 3,
					},
				},
				a11y: {
					enabled: true,
					id: 'socialFeed',
					prevSlideMessage: 'Previous slide',
					nextSlideMessage: 'Next slide',
					firstSlideMessage: 'This is the first slide',
					lastSlideMessage: 'This is the last slide',
					paginationBulletMessage: 'Go to slide {{index}}',
					slideLabelMessage: '{{index}} / {{slidesLength}}',
				},
			})
		}

		/* EVENTS */
		let eventsList = document.getElementsByClassName('events__list')[0]
		if (eventsList !== null && eventsList !== undefined) {
			createSwiper(eventsList, false, {
				init: true,
				autoplay: false,
				loop: false,
				centeredSlides: false,
				spaceBetween: 25,
				grabCursor: true,
				initialSlide: 1,
				watchSlidesProgress: true,
				watchSlidesVisibility: true,
				navigation: {
					nextEl: ".nav__next__events",
					prevEl: ".nav__prev__events",
				},
				slideVisibleClass: 'swiper-slide-visible',
				breakpoints: {
					600: {
						spaceBetween: 50,
						slidesPerView: 2,
					},
					768: {
						spaceBetween: 50,
						slidesPerView: 2.75,
					},
					1200: {
						spaceBetween: 75,
						slidesPerView: 3,
					},
				},
				a11y: {
					enabled: true,
					id: 'news__list',
					prevSlideMessage: 'Previous slide',
					nextSlideMessage: 'Next slide',
					firstSlideMessage: 'This is the first slide',
					lastSlideMessage: 'This is the last slide',
					paginationBulletMessage: 'Go to slide {{index}}',
					slideLabelMessage: '{{index}} / {{slidesLength}}',
				},
			})
		}

		/* TESTIMONIALS */
		const testimonialsList = document.querySelector(".testimonials__list");
		if (testimonialsList !== null) {
			let testimonialsSwiper = createSwiper(testimonialsList, false, {
				init: true,
				autoplay: false,
				loop: true,
				centeredSlides: true,
				spaceBetween: 25,
				grabCursor: true,
				slidesPerView: 1,
				watchSlidesProgress: true,
				watchSlidesVisibility: true,
				navigation: {
					nextEl: ".nav__next__testimonials",
					prevEl: ".nav__prev__testimonials",
				},
				pagination: {
					el: ".swiper-pagination-testimonials",
					clickable: true,
				},
				slideVisibleClass: 'swiper-slide-visible',
				breakpoints: {
					600: {
						spaceBetween: 50,
						slidesPerView: 1,
					},
					800: {
						spaceBetween: 50,
						slidesPerView: 1,
					},
					1000: {
						spaceBetween: 50,
						slidesPerView: 1,
					},
				},
				a11y: {
					enabled: true,
					id: 'testimonials__list',
					prevSlideMessage: 'Previous slide',
					nextSlideMessage: 'Next slide',
					firstSlideMessage: 'This is the first slide',
					lastSlideMessage: 'This is the last slide',
					paginationBulletMessage: 'Go to slide {{index}}',
					slideLabelMessage: '{{index}} / {{slidesLength}}',
				},
			});
		}
		

		const awardsList = document.querySelector(".awards__list");
		if (awardsList !== null) {
			let awardsSwiper = createSwiper(awardsList, false, {
				init: true,
				autoplay: false,
				navigation: {
					prevEl: ".awards__navigation__prev",
					nextEl: ".awards__navigation__next",
				},
				slidesPerView: 'auto',
				spaceBetween: 20,
				speed: 500,
				autoHeight: true,
				watchSlidesProgress: true,
				watchSlidesVisibility: true,
				slideVisibleClass: 'swiper-slide-visible',
				lazy: true,
				checkInView: false,
				freeMode: true,
				centeredSlides: false,
				centeredSlidesBounds: false,
				centerInsufficientSlides: true,			
				a11y: {
					enabled: true,
					prevSlideMessage: 'Previous slide',
					nextSlideMessage: 'Next slide',
					firstSlideMessage: 'This is the first slide',
					lastSlideMessage: 'This is the last slide',
					paginationBulletMessage: 'Go to slide {{index}}',
					slideLabelMessage: '{{index}} / {{slidesLength}}',
				},
			});
			awardsSwiper.slideTo(1, false, false);
		}

		
	}

/*--------------------------------------------------
	Inner - General
--------------------------------------------------*/
	if (blnInner){
		
	}

/*--------------------------------------------------
	Inner - Non-Admin
--------------------------------------------------*/
	if (blnInner && !blnAdmin) {
		manageContentTables()
		manageAccordions('.template--accordion__title')
	}

/*--------------------------------------------------
	Forms
--------------------------------------------------*/
	if (blnForm) {
		const formInstances = document.querySelectorAll('.content__form, form.content__block__form');

		formInstances.forEach((formContainer, instanceCount) => {
			const pages = formContainer.querySelectorAll('.form__page');

			if (pages.length > 1) {
				const formProgress = document.createElement('div');
				formProgress.classList.add('form__page__progress');
				formProgress.dataset.formcount = instanceCount;
				
				formContainer.parentNode.insertBefore(formProgress, formContainer);

				pages.forEach((page, index) => {
					const progressItem = document.createElement('div');
					progressItem.classList.add('form__page__progress__item');
					if (index === 0) {
						progressItem.classList.add('active');
					}
					progressItem.dataset.pageindex = index + 1;

					const circle = document.createElement('div');
					circle.classList.add('form__page__progress__item__circle');
					circle.innerHTML = `<span>${index + 1}</span>`;
					
					progressItem.appendChild(circle);
					formProgress.appendChild(progressItem);
				});

				document.addEventListener('click', (event) => {
					if (event.target.closest('.form__page__pagination')) {
						const button = event.target.closest('.form__page__pagination');
						const nextPage = button.dataset.pageTo;
						const parentForm = button.closest('form')
						const currentProgress = parentForm.parentNode.querySelector('.form__page__progress');
						setTimeout(() => {
							const formErrorCheck = parentForm.querySelectorAll('.content__form__error, .content__block__form__error');
							const formError = formErrorCheck.length > 0;

							if (!formError) {
								currentProgress.querySelector(`[data-pageindex="${nextPage}"]`)
								.classList.add('active');
								Array.from(currentProgress.children)
								.forEach(child => {
									if (child.dataset.pageindex !== nextPage) {
										child.classList.remove('active');
									}
								});
							}
						}, 250);
					}
				});
			}
		});
	}
}

/*--------------------------------------------------
	Access
--------------------------------------------------*/


// swiper pause
// add data-pause= swiper variable name
// will switch first and second nth-child icon

const swiperPauseButtons = document.querySelectorAll('[data-pause]')
if (swiperPauseButtons) {
	swiperPauseButtons.forEach(pause => {
		pause.addEventListener('click', swiperPauseToggle)
	})
}

function swiperPauseToggle(event) {
	const swiperPauseButton = event.target.closest('[data-pause]')
	const swiperVariableName = swiperPauseButton.getAttribute('data-pause')
	const swiperVariable = window[swiperVariableName];
	if (swiperVariable) {
		if (getCurrentAutoplayState(swiperVariable)) {
			swiperVariable.autoplay.stop()
			swiperPauseButton.classList.add('active')
		} else {
			swiperVariable.autoplay.start()
			swiperPauseButton.classList.remove('active')
		}
		
	}
}



/*--------------------------------------------------
	Admin
--------------------------------------------------*/

if (blnAdmin) {		
	
}

/*--------------------------------------------------
	Scroll Events
--------------------------------------------------*/
function customScroll(scrollPosition){
	
}

/*--------------------------------------------------
	Maps
--------------------------------------------------*/
function mapLoaded(map, markers){
	
}
